import dynamic from 'next/dynamic';
import NextLink from 'next/link';

import { Container, Link, SectionText } from '@/atoms';
import { SwiperCustom } from '@/molecules';
import { ApartmentCard } from '@/organisms';

import type { FunctionComponent } from 'react';
import type { TypeApartmentsProps } from './types';

const Tabs = dynamic(() => import('@/atoms/tabs'));

/**
 * Apartments
 */
export const Apartments: FunctionComponent<TypeApartmentsProps> = ({
  className = '',
  apartments,
  backgroundColorBottom,
  backgroundColorTop,
  caption,
  cutLevel = 50,
  desc,
  title,
  link,
  cutLevelMobile = 50,
  textColor,
  index,
  id,
  hasCutLevel,
}: TypeApartmentsProps) => {
  const tabsName = ['Precio mes', 'Precio noche'];

  const apartmentsMonth = apartments?.apartmentsCars?.filter(
    (itm) => itm.tipoestancia === 'month'
  );

  const apartmentsDay = apartments?.apartmentsCars?.filter(
    (itm) => itm.tipoestancia !== 'month'
  );

  const longitud = apartmentsDay.length + apartmentsMonth.length;

  return (
    <Container
      index={index}
      backgroundColorTop={backgroundColorTop}
      cutLevel={cutLevel === null ? 50 : cutLevel}
      cutLevelMobile={cutLevelMobile === null ? 50 : cutLevelMobile}
      backgroundColorBottom={backgroundColorBottom}
      hasCutLevel={hasCutLevel}
      className={`${
        longitud > 1 ? 'flex-col' : 'flex-col md:flex-row'
      } flex  gap-10 ${className}`}
      id={id}
    >
      <SectionText
        title={title}
        directionMobile="column"
        desc={desc}
        caption={caption}
        direction={longitud > 1 ? 'row' : 'column'}
        textAlign="start"
        className={` ${longitud > 1 ? '' : 'w-full md:max-w-[33%]'}`}
        variant={textColor}
      />

      {apartmentsMonth.length > 0 && apartmentsDay.length > 0 ? (
        <Tabs tabsName={tabsName} type="default" selectedAtFirst={0}>
          <SwiperCustom
            scrollbar={true}
            navigation
            slidesPerView={3}
            slidesPerViewMobile={longitud > 1 ? 1.2 : 1}
            gap={24}
          >
            {apartmentsMonth?.map((apartmentsCards, index) => (
              <ApartmentCard
                {...apartmentsCards}
                key={index}
                className={`h-auto ${apartmentsCards?.className ?? ''}`}
              />
            ))}
          </SwiperCustom>

          <SwiperCustom
            scrollbar={true}
            navigation
            slidesPerView={3}
            slidesPerViewMobile={1}
            gap={24}
          >
            {apartmentsDay?.map((apartmentsCards, index) => (
              <ApartmentCard
                {...apartmentsCards}
                key={index}
                className={`h-full ${apartmentsCards?.className ?? ''}`}
              />
            ))}
          </SwiperCustom>
        </Tabs>
      ) : (
        <div
          className={`${longitud > 1 ? '' : 'h-full w-full md:max-w-[66%]'}`}
        >
          <SwiperCustom
            scrollbar={true}
            navigation
            slidesPerView={3}
            slidesPerViewMobile={longitud > 1 ? 1.2 : 1}
            gap={24}
            slideGrow={true}
            className={`${longitud > 1 ? '' : ' h-full'}`}
          >
            {(apartmentsMonth || apartmentsDay)?.map(
              (apartmentsCards, index) => (
                <ApartmentCard
                  key={index}
                  {...apartmentsCards}
                  cardOrientation={longitud > 1 ? 'column' : 'row'}
                  buttonWidth={100}
                  className={`h-full ${longitud > 1 ? '' : ' h-full'}  ${
                    apartmentsCards?.className ?? ''
                  }`}
                />
              )
            )}
          </SwiperCustom>
        </div>
      )}

      <>
        {link?.link && link?.text && (
          <div className="flex justify-end">
            <NextLink passHref prefetch={false} href={link.link}>
              <Link arrow={true} as="a" variant={link?.variant}>
                {link.text}
              </Link>
            </NextLink>
          </div>
        )}
      </>
    </Container>
  );
};

Apartments.displayName = 'Apartments';
